import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link } from "react-router-dom";

const pagelocation = "Blogs";

class Blogs extends Component {
  state = {
    isLoading: true,
    blogs: [],
  };

  componentDidMount() {
    fetch("https://blog.missionhealth.nz/wp-json/wp/v2/posts")
      .then((response) => response.json())
      .then((blogList) => {
        this.setState({ blogs: blogList, isLoading: false });
      });
  }

  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>{pagelocation} - Mission Health</title>
          <meta name="description" content="#" />
        </MetaTags>
        <Header />
        {/* Body */}
        <div className="section blogHeaderSection pb-0">
          <div className="container text-center ">
            <div className="section-title mw-100">
              <h3
                className="title"
                style={{ color: "#7aa836", lineHeight: "1.21" }}
              >
                Blogs
              </h3>
            </div>
          </div>
        </div>
        <div className="container blog">
          {this.state.isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img
                src="/assets/mission health/Loader.gif"
                style={{ height: "5rem", width: "5rem" }}
                alt="Loading..."
              />
            </div>
          ) : (
            <React.Fragment>
              {this.state.blogs.length !== 0 ? (
                <div className="row">
                  {this.state.blogs.map((blog, index) => (
                    <div className="col-lg-4 col-md-6" key={index}>
                      <div className="sigma_service style-17">
                        <div className="sigma_service-thumb">
                          <img
                            src={blog.jetpack_featured_media_url}
                            alt={blog.title.rendered}
                            style={{ height: "250px", width: "100%" }}
                          />
                        </div>
                        <div className="sigma_service-body">
                          <h5 className="blogPostHeader">
                            <Link
                              className="lineClamp"
                              style={{ WebkitLineClamp: 2 }}
                              to={`/blogs/${blog.slug}`}
                            >
                              {blog.title.rendered}
                            </Link>
                          </h5>
                          <p
                            className="lineClamp"
                            style={{ WebkitLineClamp: 3 }}
                            dangerouslySetInnerHTML={{
                              __html: blog.excerpt.rendered.replace(
                                " [&hellip;]",
                                "..."
                              ),
                            }}
                          />
                          <Link
                            to={`/blogs/${blog.slug}`}
                            className="btn-link primary-color"
                          >
                            Read More
                            <i className="fal fa-arrow-right" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <h1 className="noBlogFound">
                  Check back soon for expert articles and the latest healthcare
                  news.
                </h1>
              )}
            </React.Fragment>
          )}
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default Blogs;
