import React, { Component } from "react";
import { Link } from "react-router-dom";
import expertises from "../../data/expertise/expertise.json";
import footer from "../../data/footer.json";
import { Alert, Modal } from "react-bootstrap";

function PolicyModal(props) {
  return (
    <Modal
      {...props}
      dialogClassName="policyModalClass"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.isAccPolicy && "ACC Policy"}
          {props.isRefundPolicy && "Refund Policy"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.isAccPolicy && (
          <p>
            It is our policy to collect private payment for ACC treatments until
            the ACC claim is shown on the ACC website as being approved. We will
            refund the difference once the claim is approved.
          </p>
        )}
        {props.isRefundPolicy && (
          <p>
            A cancellation fee of $30 will apply unless notification is given at
            least 24 hours in advance.
          </p>
        )}
        {props.isRefundPolicy && <p>Thank you for your cooperation.</p>}
        <img
          src={process.env.PUBLIC_URL + "/assets/img/logo516x800.svg"}
          alt="missionhealth, nz"
          style={{
            width: "150px",
            objectFit: "cover",
          }}
        />
      </Modal.Body>
    </Modal>
  );
}

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: "",
      accModal: false,
      refundModal: false,
    };
    this.onEmailChange = this.onEmailChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onEmailChange(event) {
    this.setState({ emailAddress: event.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    fetch(
      "https://m6qt9b09ni.execute-api.ap-southeast-2.amazonaws.com/prod/subscribe-newsletter",
      {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
          body: { emailAddress: this.state.emailAddress },
        }),
        headers: {
          "x-api-key": "yeF5ZpxrSy8B7ZoJtREyP1hhSoJ2qGbNaJ6N4ZkB",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.id !== "") {
          document
            .getElementById("server_response_success")
            .classList.add("d-block");
          this.resetForm();
        } else {
          document
            .getElementById("server_response_danger")
            .classList.add("d-block");
        }
      })
      .finally(() => {
        this.resetForm();
      });
  }

  resetForm() {
    this.setState({
      emailAddress: "",
    });
  }

  render() {
    return (
      <>
        <React.Fragment>
          <footer
            className="sigma_footer style-5 pb-1"
            style={{
              marginTop:
                this.props.pagelocation === "Book An Appointment" && "20px",
              paddingTop:
                this.props.pagelocation === "Book An Appointment" && "60px",
            }}
          >
            {this.props.pagelocation !== "Book An Appointment" && (
              <div className="book_appointment">
                <div
                  style={{
                    backgroundImage:
                      "url(" + process.env.PUBLIC_URL + "/imagebg.png)",
                  }}
                  className="book_appointment-inner"
                >
                  <div className="book_appointment-right">
                    <div>
                      <h1>Embrace a Healthier Future now</h1>
                      <p>
                        Say goodbye to health issues permanently! Discover our
                        safe, effective, and drug-free alternative.
                      </p>
                    </div>
                    <div>
                      <Link to="/book-an-appointment">
                        Book An Appointment
                        <i className="fa fa-regular fa-arrow-right ml-3" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="container">
              <div className="sigma_info-wrapper style-26 mb-5">
                <div className="sigma_info style-26">
                  <div className="sigma_info-title">
                    <span className="sigma_info-icon">
                      <i className="fal fa-map-marker-alt" />
                    </span>
                  </div>
                  <div className="sigma_info-description">
                    <p>Visit us</p>
                    <p className="secondary-color">
                      <b>252 Thomas Road, Rototuna North, Hamilton 3210, NZ</b>
                    </p>
                  </div>
                </div>
                <div className="sigma_info style-26">
                  <div className="sigma_info-title">
                    <span className="sigma_info-icon">
                      <i
                        className="fal fa-phone"
                        style={{ transform: "rotate(90deg)" }}
                      />
                    </span>
                  </div>
                  <div className="sigma_info-description">
                    <p>Speak with us</p>
                    <p className="secondary-color">
                      <b>07 282 0238</b>
                    </p>
                  </div>
                </div>
                <div className="sigma_info style-26">
                  <div className="sigma_info-title">
                    <span className="sigma_info-icon">
                      <i className="fal fa-clock" />
                    </span>
                  </div>
                  <div className="sigma_info-description">
                    <p>Clinic Hours</p>
                    <p className="secondary-color">
                      <b>
                        Mon-Fri: 8am-6pm <br></br>Sat: By Appointment Only
                        <br></br> Sun & Public Holidays: Closed
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="sigma_footer-middle">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="sigma_footer-widget">
                      <div className="sigma_footer-logo mb-4">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/img/logo516x800.svg"
                          }
                          alt="logo"
                          height={150}
                        />
                      </div>

                      <ul className="sigma_social-icons has-border mt-4 justify-content-start">
                        <li>
                          <a
                            href="https://www.facebook.com/Missionhealthnz?mibextid=ZbWKwL"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-facebook-f" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://instagram.com/missionhealthnz?igshid=NTc4MTIwNjQ2YQ=="
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-instagram" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/in/chirag-sojitra-73aa7087?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-linkedin-in" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.youtube.com/@MissionHealth-Chirag.Sojitra"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-youtube" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-3">
                    <div className="sigma_footer-widget">
                      <h5 className="widget-title">Expertise</h5>
                      <ul className="sigma_footer-links">
                        {expertises.slice(0, 5).map((item, i) => (
                          <li key={i}>
                            <Link to={"/expertise/" + item.id}>
                              {item.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-3">
                    <div className="sigma_footer-widget">
                      <h5 className="widget-title">Useful Links</h5>
                      <ul className="sigma_footer-links">
                        {footer.map((item) => (
                          <li key={item.id}>
                            <Link to={item.link}>{item.linkText}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="sigma_footer-widget">
                      <h5 className="widget-title">Receive Our Updates</h5>
                      <form onSubmit={this.handleSubmit}>
                        <input
                          type="email"
                          placeholder="Email Address"
                          name="emailAddress"
                          value={this.state.emailAddress}
                          onChange={this.onEmailChange}
                          required
                        />
                        <button type="submit" className="mt-3 btn-block">
                          Subscribe
                        </button>
                        <Alert
                          variant="success"
                          className="d-none mt-3 mb-0 subScribeBtn"
                          id="server_response_success"
                          dismissible
                          onClose={() => {
                            document
                              .getElementById("server_response_success")
                              .classList.remove("d-block");
                          }}
                        >
                          <strong>Success!</strong> Subscribed Successfully.
                        </Alert>
                        <Alert
                          variant="danger"
                          className="d-none mt-3 mb-0 subScribeBtn"
                          id="server_response_danger"
                          dismissible
                          onClose={() => {
                            document
                              .getElementById("server_response_danger")
                              .classList.remove("d-block");
                          }}
                        >
                          <strong>Oops!</strong> Something happened. Please try
                          again later.
                        </Alert>
                        <p className="mb-0 mt-3">
                          Receive the most recent updates via email. You can
                          unsubscribe at any time.
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <p className="mb-0" style={{ textAlign: "justify" }}>
                <b>Disclaimer : </b>The information provided on this website is
                intended for informational purposes only and does not constitute
                professional medical advice. Please consult a healthcare
                provider for diagnosis and treatment. Do not self-diagnose or
                self-treat based on this information. For personalized care,
                seek the advice of a qualified medical professional.
              </p>
              <div className="sigma_footer-bottom d-block d-sm-flex align-items-center justify-content-between mt-0">
                <div className="sigma_footer-copyright mt-0 mb-3 mb-sm-0">
                  <p className="mb-0">
                    © Copyright 2024 | All Rights Reserved | Crafted with ♥ by{" "}
                    <a
                      href="https://www.smartsensesolutions.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      smartSense Consulting Solutions Pvt. Ltd
                    </a>
                  </p>
                </div>
                <ul className="sigma_footer-links">
                  <li>
                    <button
                      className="footerBtn"
                      onClick={() => this.setState({ accModal: true })}
                    >
                      ACC Policy
                    </button>
                  </li>
                  <li>
                    <button
                      className="footerBtn"
                      onClick={() => this.setState({ refundModal: true })}
                    >
                      Cancellation Policy
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </footer>
        </React.Fragment>
        <PolicyModal
          show={this.state.accModal || this.state.refundModal}
          onHide={() => this.setState({ accModal: false, refundModal: false })}
          isAccPolicy={this.state.accModal}
          isRefundPolicy={this.state.refundModal}
        />
      </>
    );
  }
}

export default Footer;
