import React, { Suspense, useLayoutEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import Blogs from "./components/pages/Blogs";

const Home = React.lazy(() => import("./components/pages/Home"));
const WhoAreWe = React.lazy(() => import("./components/pages/WhoAreWe"));
const TreatmentProcess = React.lazy(() =>
  import("./components/pages/TreatmentProcess")
);
const Expertise = React.lazy(() => import("./components/pages/Expertise"));
const IndividualExpertise = React.lazy(() =>
  import("./components/pages/IndividualExpertise")
);
const Treatment = React.lazy(() => import("./components/pages/Treatment"));
const IndividualTreatment = React.lazy(() =>
  import("./components/pages/IndividualTreatment")
);
const IndividualBlog = React.lazy(() =>
  import("./components/pages/IndividualBlog")
);
const Gallery = React.lazy(() => import("./components/pages/Gallery"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Errorpage = React.lazy(() => import("./components/pages/Errorpage"));
const BookAnAppointment = React.lazy(() =>
  import("./components/pages/BookAnAppointment")
);
const Faqs = React.lazy(() => import("./components/pages/Faqs"));

const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
});

function App() {
  return (
    <Router basename={"/"}>
      <Suspense fallback={<div></div>}>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/discover-missionhealth" component={WhoAreWe} />
            <Route
              exact
              path="/treatment-process"
              component={TreatmentProcess}
            />
            <Route exact path="/faqs" component={Faqs} />
            <Route exact path="/expertise" component={Expertise} />
            <Route
              path="/expertise/:id"
              exact
              component={(props) => (
                <IndividualExpertise
                  {...props}
                  key={window.location.pathname}
                />
              )}
            />
            <Route exact path="/treatment" component={Treatment} />
            <Route
              path="/treatment/:id"
              exact
              component={(props) => (
                <IndividualTreatment
                  {...props}
                  key={window.location.pathname}
                />
              )}
            />
            <Route
              path="/treatment/pain-relief/:id"
              exact
              component={(props) => (
                <IndividualTreatment
                  {...props}
                  key={window.location.pathname}
                />
              )}
            />
            <Route
              path="/treatment/womens-health/:id"
              exact
              component={(props) => (
                <IndividualTreatment
                  {...props}
                  key={window.location.pathname}
                />
              )}
            />
            <Route
              path="/treatment/mental-health/:id"
              exact
              component={(props) => (
                <IndividualTreatment
                  {...props}
                  key={window.location.pathname}
                />
              )}
            />
            <Route
              path="/treatment/neurological-disorder/:id"
              exact
              component={(props) => (
                <IndividualTreatment
                  {...props}
                  key={window.location.pathname}
                />
              )}
            />
            <Route
              path="/treatment/respiratory-disorders/:id"
              exact
              component={(props) => (
                <IndividualTreatment
                  {...props}
                  key={window.location.pathname}
                />
              )}
            />
            <Route
              path="/treatment/skin-diseases/:id"
              exact
              component={(props) => (
                <IndividualTreatment
                  {...props}
                  key={window.location.pathname}
                />
              )}
            />
            <Route
              path="/treatment/cardiovascular-health/:id"
              exact
              component={(props) => (
                <IndividualTreatment
                  {...props}
                  key={window.location.pathname}
                />
              )}
            />
            <Route
              path="/treatment/male-health/:id"
              exact
              component={(props) => (
                <IndividualTreatment
                  {...props}
                  key={window.location.pathname}
                />
              )}
            />
            <Route
              path="/treatment/diabetes/:id"
              exact
              component={(props) => (
                <IndividualTreatment
                  {...props}
                  key={window.location.pathname}
                />
              )}
            />
            <Route
              path="/treatment/allergies/:id"
              exact
              component={(props) => (
                <IndividualTreatment
                  {...props}
                  key={window.location.pathname}
                />
              )}
            />
            <Route exact path="/gallery" component={Gallery} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/blogs" component={Blogs} />
            <Route
              exact
              path="/blogs/:id"
              component={(props) => <IndividualBlog {...props} />}
            />
            <Route
              exact
              path="/book-an-appointment"
              component={BookAnAppointment}
            />
            <Route exact path="/error-page" component={Errorpage} />
            <Route component={Errorpage} />
          </Switch>
        </ScrollToTop>
      </Suspense>
    </Router>
  );
}

export default App;
