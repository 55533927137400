import React, { Fragment } from "react";
import Navhelper from "../../helper/NavHelper";
import Mobilemenu from "./Mobilemenu";
import { Link } from "react-router-dom";
import navigation from "../../data/navigation.json";

class Header extends Navhelper {
  render() {
    return (
      <Fragment>
        {/* Mobile Menu */}
        <aside
          className={
            this.state.navMethod === true
              ? "sigma_aside aside-open"
              : "sigma_aside"
          }
        >
          <div className="sigma_close aside-trigger" onClick={this.toggleNav}>
            <span />
            <span />
          </div>
          <Mobilemenu />
        </aside>
        <div
          className="sigma_aside-overlay aside-trigger"
          onClick={this.toggleNav}
        />
        {/* Mobile Menu */}
        {/* Header */}
        <header className="sigma_header header-absolute style-5 other can-sticky">
          <div className="sigma_header-top white-bg d-none d-md-block">
            <div className="container-fluid">
              <div className="sigma_header-top-inner">
                <div className="sigma_header-top-links">
                  <ul className="sigma_header-top-nav">
                    <li>
                      <a href="mailto:info@missionhealth.nz">
                        <i className="fal fa-envelope" />
                        info@missionhealth.nz
                      </a>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fal fa-map-marker-alt" />
                        252 Thomas Road, Rototuna North, Hamilton 3210, NZ
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="sigma_header-top-contacts">
                  <ul className="sigma_header-top-nav">
                    <li>
                      <a href="https://www.facebook.com/Missionhealthnz?mibextid=ZbWKwL" target="_blank" rel="noreferrer">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="https://instagram.com/missionhealthnz?igshid=NTc4MTIwNjQ2YQ" target="_blank" rel="noreferrer">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/in/chirag-sojitra-73aa7087?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noreferrer">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/@MissionHealth-Chirag.Sojitra" target="_blank" rel="noreferrer">
                        <i className="fab fa-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="sigma_header-middle bg-header">
            <div className="container-fluid">
              <div className="navbar">
                <div className="sigma_logo-wrapper">
                  <Link to="/" className="sigma_logo">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/img/logo-title.svg"
                      }
                      alt="logo"
                    />
                  </Link>
                </div>
                <ul className="navbar-nav">
                  {/* Data */}
                  {navigation.map((item, i) => (
                    <li
                      key={i}
                      className={
                        item.child === true
                          ? "menu-item menu-item-has-children"
                          : "menu-item"
                      }
                    >
                      {item.child === true ? (
                        <Link
                          to={!!item.link ? item.link : "#"}
                          style={{ color: "#4C4C4C" }}
                          className="navbarLink"
                        >
                          {item.linkText}
                        </Link>
                      ) : (
                        <Link
                          to={item.link}
                          style={{ color: "#4C4C4C" }}
                          className="navbarLink"
                        >
                          {item.linkText}
                        </Link>
                      )}
                      {item.child === true ? (
                        <ul
                          className={`sub-menu ${
                            item.hasColumn && "sub-menu-withColumn"
                          }`}
                        >
                          {item.submenu.map((item, i) => (
                            <li
                              key={i}
                              className={
                                item.child === true
                                  ? "menu-item menu-item-has-children"
                                  : "menu-item"
                              }
                            >
                              {item.child === true ? (
                                <Link
                                  to="#"
                                  style={{ color: "#4C4C4C" }}
                                  className="navbarLink"
                                >
                                  {item.linkText}
                                </Link>
                              ) : (
                                <Link
                                  to={item.link}
                                  style={{ color: "#4C4C4C" }}
                                  className="navbarLink"
                                >
                                  {item.linkText}
                                </Link>
                              )}
                              {item.child === true ? (
                                <ul className="sub-menu">
                                  {item.submenu.map((item, i) => (
                                    <li className="menu-item" key={i}>
                                      <Link to={item.link}>
                                        {item.linkText}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                ""
                              )}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
                  {/* Data */}
                </ul>
                <div className="sigma_header-controls style-2">
                  <ul className="sigma_header-controls-inner">
                    <li className="search-trigger header-controls-item d-none d-sm-block">
                      {/* <Link
                          to="#"
                          className="sigma_header-control-search bg-transparent border-0"
                          title="Search"
                          onClick={this.toggleSearch}
                        >
                          <i
                            className="far fa-search"
                            style={{ color: "#4c4c4c" }}
                          />
                        </Link> */}
                    </li>
                    <li className="d-none d-sm-block">
                      <Link
                        to="/book-an-appointment"
                        className="sigma_btn btn-sm "
                      >
                        Book an appointment
                        {/* <i className="fal fa-arrow-right" /> */}
                      </Link>
                    </li>
                    <li
                      className="aside-toggle aside-trigger"
                      onClick={this.toggleNav}
                    >
                      <span />
                      <span />
                      <span />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* Header */}
        {/* Search Bar */}
        <div
          className={
            this.state.searchMethod === true
              ? "search-form-wrapper open"
              : "search-form-wrapper"
          }
        >
          <div
            className="search-trigger sigma_close"
            onClick={this.toggleSearch}
          >
            <span />
            <span />
          </div>
          <form className="search-form">
            <input type="text" placeholder="Search..." required />
            <button type="submit" className="search-btn">
              <i className="fal fa-search m-0" />
            </button>
          </form>
        </div>
        {/* Search Bar */}
      </Fragment>
    );
  }
}

export default Header;
